import http from "utils/http";
import { useState, useEffect } from "react";

export default (organizationId) => {
  const [indicator, setIndicator] = useState(null);

  useEffect(() => {
    getIndicator(0, 100000);
  }, []);

  async function getIndicator(fromSize, toSize) {
    const response = await http.get(
      "/api/organizations/" +
        organizationId +
        "/indicators?fromsize=" +
        fromSize +
        "&tosize=" +
        toSize
    );
    setIndicator(response.data);
  }

  return [indicator, getIndicator];
};
