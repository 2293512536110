import React from "react";
import { useParams } from "react-router-dom";
import useText from "services/useText";
import useOrganization from "../../services/useOrganization";
import OrganizationContent from "./Components/OrganizationContent";
import HTMLText from "components/HTMLText";
import "./styles.scss";

const OrganizationSelfAdministration = () => {
  const { organizationId } = useParams();
  const [org, addOrgEditor, removeOrgEditor] = useOrganization(organizationId);
  const text = useText();

  return (
    <div className="OrganizationSelfAdministration">
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h1>{text.DIGITALISERINGS_GRUPPEN.TITLE}</h1>
            <HTMLText>{text.DIGITALISERINGS_GRUPPEN.INTRO}</HTMLText>
            <p className="small">{text.DIGITALISERINGS_GRUPPEN.SMALL}</p>

            <OrganizationContent
              org={org}
              addOrgEditor={addOrgEditor}
              removeOrgEditor={removeOrgEditor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSelfAdministration;
