import React from "react";
import { Table } from "react-bootstrap";
import SystemAdminRow from "./SystemAdminRow";

const SystemAdminTable = (props) => {
  const { systemAdmins, removeSystemAdmin, postInvitation } = props;

  const systemAdminRows = [
    systemAdmins.map((e, index) => (
      <SystemAdminRow
        key={index}
        systemAdmin={e}
        removeSystemAdmin={(e) => removeSystemAdmin(e)}
        // TODO: Fix subject and copy
        postInvitation={postInvitation}
      />
    )),
  ];

  const tableHeader = (
    <thead>
      <tr aria-label="table header">
        <th>Epostadresser</th>
      </tr>
    </thead>
  );

  return (
    <Table striped bordered hover>
      {tableHeader}
      <tbody>{systemAdminRows}</tbody>
    </Table>
  );
};

export default SystemAdminTable;
