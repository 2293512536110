import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import useText from "../../services/useText";
import "./style.scss";

export default ({ data, onLabelClick }) => {
  const text = useText();

  const getDimText = (index) => {
    if (index === 0) {
      return text.RADAR.INDIKATOR_organizationVisionStrategy;
    } else if (index === 1) {
      return text.RADAR.INDIKATOR_organizationManagement;
    } else if (index === 2) {
      return text.RADAR.INDIKATOR_organizationCulture;
    } else if (index === 3) {
      return text.RADAR.INDIKATOR_organizationCompetence;
    } else if (index === 4) {
      return text.RADAR.INDIKATOR_organizationInnovation;
    } else if (index === 5) {
      return text.RADAR.INDIKATOR_informationInternalProcess;
    } else if (index === 6) {
      return text.RADAR.INDIKATOR_informationInterfaceActors;
    } else if (index === 7) {
      return text.RADAR.INDIKATOR_productBuildings;
    }
  };

  function customTick({ payload, x, y, textAnchor, stroke, radius }) {
    const width = 400;
    if (payload.index > 0 && payload.index < 4) {
      x += 20;
      y -= 40;
    } else if (payload.index > 4) {
      x -= width;
      y -= 40;
    } else if (payload.index == 4) {
      x -= width / 2;
    } else {
      x -= width / 2;
      y -= 40;
    }

    return (
      <foreignObject class="label-wrapper" x={x} y={y}>
        <div class="custom-label">
          <h3>
            {payload.value} -{" "}
            {Math.round(data.dimensions[payload.index].score * 100)}%
          </h3>
          <p>{getDimText(payload.index)} </p>
        </div>
      </foreignObject>
    );
  }

  function customDot({ cx, cy }) {
    return <circle r={7} cx={cx} cy={cy} fill="#413AA0" />;
  }

  return (
    <ResponsiveContainer width="100%" aspect="2" className="polarChart">
      <RadarChart cx="50%" cy="50%" outerRadius="75%" data={data.dimensions}>
        <PolarGrid wrapperStyle={{ fontSize: 14 }} />
        <PolarAngleAxis dataKey="subject" tick={customTick} />
        <PolarRadiusAxis domain={[0, 1]} tick={false} />
        <Radar
          name="Mitt bolag"
          dataKey="score"
          stroke="#675EED"
          fill="#675EED"
          fillOpacity={0.4}
          dot={customDot}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};
