import React from "react";
import { Accordion } from "react-bootstrap";
import OrgAccordionItem from "./OrgAccordionItem";

const OrganizationAccordions = (props) => {
  const {
    orgs,
    removeOrg,
    addOrgAdmin,
    removeOrgAdmin,
    addOrgEditor,
    removeOrgEditor,
    postInvitation,
  } = props;

  const organizationItems = [
    orgs.map((org, index) => (
      <Accordion key={index}>
        <OrgAccordionItem
          eventKey={index}
          org={org}
          removeOrg={removeOrg}
          addOrgAdmin={addOrgAdmin}
          removeOrgAdmin={removeOrgAdmin}
          addOrgEditor={addOrgEditor}
          removeOrgEditor={removeOrgEditor}
          postInvitation={postInvitation}
        />
      </Accordion>
    )),
  ];

  return organizationItems;
};

export default OrganizationAccordions;
