import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Introduction from "./Introduktion";
import Completed from "./Completed";
import SurveyPages from "./SurveyPages";

const CurrentSurvey = () => {
  const IntroShown = ({ children }) => {
    const shownIntro = localStorage.getItem("shownIntro");
    return shownIntro ? children : <Navigate to={"introduction"} />;
  };

  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={
            <IntroShown>
              <SurveyPages />
            </IntroShown>
          }
        />
        <Route path={"introduction"} element={<Introduction />} />
        <Route path={"completed"} element={<Completed />} />
      </Routes>
    </>
  );
};

export default CurrentSurvey;
