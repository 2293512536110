import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";

const SystemAdminRow = (props) => {
  const { systemAdmin, removeSystemAdmin, postInvitation } = props;

  return (
    <tr>
      <td className="SystemAdminRow d-flex flex-wrap">
        <p className="m-0">{systemAdmin.email}</p>
        <div>
          {/* <Button
            className="me-2"
            size="sm"
            variant="info"
            onClick={() => {
              postInvitation("SYS_ADMIN", systemAdmin.email, "Subject", "Copy");
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Skicka ny inbjudan"
            aria-label="send email"
            role="button"
          >
            Ny inbjudan
            <FontAwesomeIcon
              icon={faEnvelope}
              className="ms-1"
              aria-hidden="true"
            />
          </Button> */}

          <Button
            size="sm"
            variant="danger"
            className="me-2"
            onClick={() => {
              removeSystemAdmin(systemAdmin);
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Ta bort administratör"
            aria-label="delete"
            role="button"
          >
            Ta bort
            <FontAwesomeIcon
              icon={faTrash}
              className="ms-1"
              aria-hidden="true"
            />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default SystemAdminRow;
