import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import useText from "../../services/useText";
import useUser from "../../services/useUser";

import "./style.scss";

const Home = () => {
  const user = useUser();
  const text = useText();

  return (
    <div className="home-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        {user && (
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>Välkommen till Byggherrelyftet</h1>
                <p>
                  Byggherrelyftet är ett strategiskt projekt inom Smart Built
                  Environment med målet att öka byggherrars digitala mognad.
                  Byggherrelyftets första fas har drivits från slutet av 2021
                  till mars 2023 av Friends of Gothenburg (FOG) i samarbete med
                  APAE och Where is My Pony i partnerskap med Sveriges
                  Allmännytta, föreningen Byggherrarna och byggherrarna
                  Jernhusen, Mölndalsnbostäder, Växjö Fastighets AB, Örebro
                  kommun, Örebrobostäder och Skanska Nya Hem. ​
                </p>
                <p>
                  På den här kan sidan hittar du{" "}
                  <Link to={"/digiradar-start"}>Digitaliseringsradarn</Link>,
                  ett digitalt verktyg som tagits fram inom Byggherrelyftet.
                  Verktyget kan användas för att kartlägga din organisations
                  nuläge vad gäller digital mognad och är framtaget för att
                  hjälpa byggherrar att på ett enkelt och strukturerat sätt få
                  en överblick och komma igång med digitalisering.​
                </p>
                <p>
                  Vill du veta mer om hur projektet fortsätter? Maila{" "}
                  <a href="staffan@foginnovation.se">
                    staffan@foginnovation.se
                  </a>
                  {"."}
                </p>
                <Link className="d-block mb-5 mt-4" to={"/digiradar-start"}>
                  <Button variant="action" className="mb-4">
                    Gå till digitaliseringsradarn
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default Home;
