import http from "utils/http";
import { useState, useEffect } from "react";

function useOrganizationResponse(organizationId, oid) {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    getResponse();
  }, []);

  async function getResponse() {
    const response = await http.get(
      "/api/organizations/" + organizationId + "/response/" + oid
    );
    setResponse(response.data);
  }

  return response;
}

export default useOrganizationResponse;
