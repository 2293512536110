// import { useForm } from "react-hook-form";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { useState } from "react";
// import dayjs from "dayjs";

const UserModal = ({ show, handleClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleClose(username, password);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Lägg till användare</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Användarnamn</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
            {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Lösenord</Form.Label>
            <Form.Control
              type="password"
              placeholder=""
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Avbryt
          </Button>
          <Button type="submit" variant="primary">
            Skapa
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserModal;
