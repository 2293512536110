import http from "utils/http";
import { useEffect, useState } from "react";

let gSurvey = null;

function useSurvey() {
  const [survey, setSurvey] = useState(gSurvey);

  useEffect(() => {
    async function getSurvey() {
      if (gSurvey) {
        setSurvey(gSurvey);
      } else {
        const response = await http.get("/api/survey-model");
        gSurvey = response.data;
        setSurvey(response.data);
      }
    }
    getSurvey();
  }, []);

  return survey;
}

export default useSurvey;
