import { useNavigate } from "react-router-dom";

const TextWithLinks = ({ children, className }) => {
  const navigate = useNavigate();

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) {
      return;
    }
    const linkURL = new URL(targetLink.href);
    const externalLink = linkURL.hostname !== window.location.hostname;
    if (externalLink) {
      return;
    }
    e.preventDefault();
    navigate(linkURL.pathname);
    // const newURL = targetLink.href.replace(
    //   /^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/,
    //   "$1"
    // );
    // console.log()
    // navigate("/" + newURL);
  };

  // Sanitize HTML here.

  return (
    <p
      className={className}
      onClick={contentClickHandler}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    ></p>
  );
};

export default TextWithLinks;
