import http from "utils/http";
import { useState, useEffect } from "react";

function useStats(organizationId) {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    getStats();
  }, []);

  async function getStats() {
    const response = await http.get(
      "/api/organizations/" + organizationId + "/responses"
    );
    setStats(response.data);
  }

  return stats;
}

export default useStats;
