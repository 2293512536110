import http from "utils/http";
import { useState, useEffect } from "react";

function useRadars(organizationId) {
  const [radars, setRadar] = useState([]);

  useEffect(() => {
    getRadars();
  }, []);

  async function getRadars() {
    const response = await http.get(
      "/api/organizations/" + organizationId + "/radars"
    );
    setRadar(response.data);
  }

  return [radars];
}

export default useRadars;
