import http from "utils/http";
import { useState } from "react";

function useInvitation() {
  const [invitationFailed, setInvitationFailed] = useState(false);

  async function postInvitation(role, email, subject, copy) {
    const body = { role: role, email: email, subject: subject, copy: copy };
    try {
      await http.post("/api/send-invitation", body);
    } catch (error) {
      console.log("Något gick snett med att skicka en inbjudan");
      setInvitationFailed(true);
      setTimeout(() => setInvitationFailed(false), 3000);
    }
  }

  return [invitationFailed, postInvitation];
}

export default useInvitation;
