import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import SystemAdminTable from "./components/SystemAdminTable";
import useInvitation from "../../services/useInvitation";
import useSystemAdmins from "../../services/useSystemAdmins";
import "./styles.css";

const SystemAdministration = () => {
  const [systemAdmins, addSystemAdmin, removeSystemAdmin] = useSystemAdmins();
  const postInvitation = useInvitation();

  function AddSysAdminButton() {
    return (
      <Button variant="success" onClick={addSystemAdmin}>
        <FontAwesomeIcon
          icon={faUserPlus}
          className="me-1"
          aria-hidden="true"
        />
        Lägg till en systemadministratör
      </Button>
    );
  }

  return (
    <div className="container-fluid SystemAdministration">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Systemadministratörer</h1>

            <SystemAdminTable
              systemAdmins={systemAdmins}
              removeSystemAdmin={removeSystemAdmin}
              postInvitation={postInvitation}
            />
            <AddSysAdminButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemAdministration;
