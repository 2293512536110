import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const Privacy = () => {
  return (
    <div className="privacy route-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="mb-5">
            <h1>Behandling av personuppgifter </h1>
            <p>
              Allmännyttan Digitaliseringsinitiativ är en oberoende initiativ
              där 100 allmännyttiga bostadsbolag gått samman och finansierat en
              satsning för att driva hållbar digitalisering och stärka
              allmännyttans förmåga att samverka kring digitalt driven
              verksamhetsutveckling. Vi verkar för hållbar och inkluderande
              digitalisering av Sveriges hyresbestånd och möjliggöra innovation
              som främjar datadrivenhet och digital mognad i bostadsbolagen.
              Initiativet pågår 2020-2022 och är nära kopplat till Allmännyttans
              Klimatinitiativ, bägge samordnas genom branchorganisationen
              Sveriges Allmännytta.
              <br />
              <br />
              Sveriges Allmännytta är enligt gällande dataskyddslagstiftning
              personuppgiftsansvarig för personuppgifter som behandlas i samband
              med Allmännyttans Digitaliseringsinitiativs ansvarsområden,
              evenemang och tjänster. Sveriges Allmännytta är också
              personuppgiftsansvarig för personuppgifter som behandlas på denna
              webbplats.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Privacy;
