import { Routes, Route, useParams } from "react-router-dom";
import useStats from "services/useStats";
import AbilitiesPage from "./AbilitiesPage";
import AbilitiesDetailPage from "./AbilitiesPage/AbilitiesDetailPage";
import ITPage from "./ITPage";
import ITDetailsPage from "./ITPage/DetailsPage";
import BusinessNeedsPage from "./BusinessNeedsPage";
import BusinessNeedsDetailPage from "./BusinessNeedsPage/BusinessNeedsDetailPage";
import CoopPage from "./CoopPage";
import OrganizationInfoPage from "./OrganizationInfoPage";
import CompareNav from "./CompareNav";
import StatsDashboard from "./StatsDashboard";
import "./styles.scss";

const ComparePage = () => {
  const { organizationId } = useParams();
  const stats = useStats(organizationId);

  return (
    <div className="compare-pages">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Jämför bolag</h1>
            {stats && (
              <StatsDashboard
                stats={stats}
                organizationId={organizationId}
                questions="base"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePage;
