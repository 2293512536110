import React from "react";

import useInvitation from "../../services/useInvitation";
import useOrganizations from "../../services/useOrganizations";
import AddOrgForm from "./components/AddOrgForm";
import OrganizationAccordions from "./components/OrgAccordion";
import { Alert } from "react-bootstrap";
import "./styles.css";

const OrganizationAdministration = () => {
  const [
    orgs,
    addOrg,
    removeOrg,
    addOrgAdmin,
    removeOrgAdmin,
    addOrgEditor,
    removeOrgEditor,
  ] = useOrganizations();
  const [invitationFailed, postInvitation] = useInvitation();

  return (
    <div className="containter-fluid OrganizationAdministration">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Organisationsadministration</h1>
            <AddOrgForm addOrg={addOrg} />

            <hr />
            <OrganizationAccordions
              orgs={orgs}
              removeOrg={removeOrg}
              addOrgAdmin={addOrgAdmin}
              removeOrgAdmin={removeOrgAdmin}
              addOrgEditor={addOrgEditor}
              removeOrgEditor={removeOrgEditor}
              postInvitation={postInvitation}
            />
            {invitationFailed && (
              <Alert className="mt-4" variant="danger">
                Misslyckades att skicka inbjudan
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationAdministration;
