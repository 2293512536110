import axios from "axios";

const http = async (method, url, body) => {
  if (method === "get") {
    return await axios.get(url);
  } else {
    return await axios[method](url, body);
  }
};

const get = async (url) => http("get", url);
const post = async (url, body) => http("post", url, body);
const put = async (url, body) => http("put", url, body);
const remove = async (url, body) => http("delete", url, body);

export default {
  get,
  post,
  put,
  remove,
};
