import React, { useState } from "react";
import { Accordion, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTrash,
  faUserPlus,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { getCompletionStatus } from "utils/response";
import UserModal from "./UserModal";

import "./styles.css";

const OrgAccordionItem = (props) => {
  const {
    eventKey,
    org,
    removeOrg,
    addOrgAdmin,
    removeOrgAdmin,
    addOrgEditor,
    removeOrgEditor,
    postInvitation,
  } = props;
  const [showUserModal, setShowUserModal] = useState(false);

  const handleCloseUserModal = async (username, password) => {
    if (username && password) {
      await addOrgAdmin(org, username, password);
    }
    setShowUserModal(false);
  };

  if (org.surveys.length > 0) {
    console.log(org.name, org.surveys[org.surveys.length - 1].response);
  }

  const orgAdminList = [
    org.administrators.map((admin, index) => (
      <tr className="d-flex justify-content-between" key={index}>
        <td className="w-100 d-flex justify-content-between align-items-baseline">
          <p className="m-0 ms-2">
            {admin.email}
            {admin.lastLogin && (
              <span className="lastLogin">Inloggad: {admin.lastLogin}</span>
            )}
          </p>

          <div>
            <Button
              variant="info"
              size="sm"
              className="me-2"
              onClick={() => {
                postInvitation("ORG_ADMIN", admin.email, "Subject", "Copy");
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Skicka ny inbjudan"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
            <Button
              variant="danger"
              size="sm"
              className="me-2"
              onClick={() => {
                removeOrgAdmin(org, admin);
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Ta bort användare"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </td>
      </tr>
    )),
  ];

  const orgEditorList = [
    org.editors.map((editor, index) => (
      <tr className="d-flex justify-content-between" key={index}>
        <td className="w-100 d-flex justify-content-between align-items-baseline">
          <p className="m-0 ms-2">
            {editor.email}
            {editor.lastLogin && (
              <span className="lastLogin">Inloggad: {editor.lastLogin}</span>
            )}
          </p>
          <div>
            <Button
              variant="info"
              size="sm"
              className="me-2"
              onClick={() => {
                postInvitation("EDITOR", editor.email, "Subject", "Copy");
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Skicka ny inbjudan"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
            <Button
              variant="danger"
              size="sm"
              className="me-2"
              onClick={() => {
                removeOrgEditor(org, editor);
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Ta bort redaktör"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </td>
      </tr>
    )),
  ];

  const body = (
    <Accordion.Body className="flex-column">
      <Button variant="danger" size="sm" onClick={() => removeOrg(org)}>
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
        Ta bort organisation
      </Button>

      <hr />

      <div className="d-flex align-items-baseline justify-content-between py-1 m-0">
        <h4 className="pe-2">Användare</h4>
        <Button
          variant="success"
          size="sm"
          // onClick={() => addOrgAdmin(org, postInvitation)}
          onClick={() => setShowUserModal(true)}
          data-toggle="tooltip"
          data-placement="top"
          title="Lägg till organisationsadministratör"
        >
          Lägg till användare
          <FontAwesomeIcon icon={faUserPlus} className="ms-1" />
        </Button>
      </div>
      <Table striped bordered hover className="d-flex">
        <tbody className="w-100">{orgAdminList}</tbody>
      </Table>

      <hr />

      {/* <div className="d-flex align-items-baseline justify-content-between py-1 m-0">
        <h4 className="pe-2">Redaktörer</h4>
        <Button
          variant="success"
          size="sm"
          onClick={() => addOrgEditor(org, postInvitation)}
          data-toggle="tooltip"
          data-placement="top"
          title="Lägg till organisationsredaktör"
        >
          Lägg till redaktör
          <FontAwesomeIcon icon={faUserPlus} className="ms-1" />
        </Button>
      </div>
      <Table striped bordered hover className="d-flex">
        <tbody className="w-100">{orgEditorList}</tbody>
      </Table> */}
      <UserModal show={showUserModal} handleClose={handleCloseUserModal} />
    </Accordion.Body>
  );

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header className="d-relative">
        {org.name}{" "}
        <span className="last-completed">{getCompletionStatus(org)}</span>
      </Accordion.Header>
      {body}
    </Accordion.Item>
  );
};

export default OrgAccordionItem;
