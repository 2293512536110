import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const About = () => {
  return (
    <div className="about route-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        <Container>
          <Row>
            <Col md={8} className="mb-5">
              <h1>Bakgrund till Digitaliseringsradarn</h1>
              <p className="paragraph-big">
                Digitaliseringsradarn för byggherrar har tagits fram inom
                Byggherrelyftet, ett strategiskt projekt inom Smart Built
                Environment i syfte att öka kunskapen, mognaden och förmågan hos
                byggherrar att nyttja digitalisering. Byggherrelyftets första
                fas har drivits av Friends of Gothenburg (FOG) i samarbete med
                APAE och Where is My Pony från slutet av 2021 till mars 2023.​
              </p>
              <p className="paragraph-big">
                Radarn och frågorna har tagits fram i samarbete med våra
                partners i projektet: Sveriges Allmännytta, föreningen
                Byggherrarna och byggherrarna Jernhusen, Mölndalsnbostäder,
                Växjö Fastighets AB, Örebro kommun, Örebrobostäder och Skanska
                Nya Hem. Vi har också fått värdefull input från experter inom
                området.
              </p>
              <p className="paragraph-big">
                Viktiga kunskapskällor vi har använt oss av i utvecklingen är
                Byggherredriven Innovation (FOG, Chalmers, KTH, 2022), Från
                Kunskap till Nytta (APAE, 2022) och liknande mognads-verktyg med
                andra målgrupper: Digibarometern (Lantmäteriet),
                Digitaliseringsradarn för Bostadsbolag (Sveriges Allmännytta)
                och Att Leda Digital Transformation (Jansson, Andervin 2016).
              </p>
              <p className="paragraph-big">
                Radarn kommer att fortsätta utvecklas och förbättras. Vill du
                veta mer? Maila{" "}
                <a href="mailto:saskia@foginnovation.se">
                  saskia@foginnovation.se
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default About;
