import { useRef } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import useText from "../../services/useText";
import useUser from "../../services/useUser";

import "./style.scss";

const Home = () => {
  const user = useUser();
  const text = useText();

  return (
    <div className="home-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        {user && (
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h1>Välkommen till digitaliseringsradarn för byggherrar</h1>
                <p>
                  Nu har du kommit till digitaliseringsradarn för byggherrar -
                  ett digitalt verktyg för att kartlägga din organisations
                  nuläge. Vi vill hjälpa byggherrar att på ett enkelt och
                  strukturerat sätt få en överblick över nuläget av sin digitala
                  mognad. Att få en greppbar och gemensam bild av er
                  organisations styrkor och svagheter är ett väldigt bra första
                  steg. Det ger er en bild av ert nuläge och en möjlighet och
                  att prioritera vilka steg ni vill ta för att öka er digitala
                  förmåga.
                </p>
                <p>
                  Verktyget är designat för att hjälpa just
                  byggherre-organisationer att påbörja sitt arbete med att öka
                  sin digitala mognad. Byggherrerollen är unik och detta verktyg
                  utgår särskilt från rollens specifika krav, förväntningar och
                  position i värdekedjan.
                </p>
                <Link to="/introsteps">
                  <Button
                    variant={user && user.username ? "hollow" : "action"}
                    className="mb-4 mt-2"
                  >
                    LÄS MER OM HUR VERKTYGET FUNGERAR
                  </Button>
                </Link>
                {user && user.username && (
                  <Link
                    className="d-block mb-5"
                    to={
                      "./organizations/" +
                      user.organizationId +
                      "/current-survey/introduction"
                    }
                  >
                    <Button variant="action" className="mb-4">
                      {text.HOME.PÅBÖRJA_KARTLÄGGNING}
                    </Button>
                  </Link>
                )}
              </Col>
              <Col className="d-none d-lg-flex justify-content-end">
                <div>
                  <img
                    className="screen-dump-survey d-block"
                    src="dump-survey.png"
                  />
                  <img
                    className="screen-dump-result d-block"
                    src="dump-result.png"
                  />{" "}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Link to="/introsteps">
                  <Button
                    variant={user && user.username ? "hollow" : "action"}
                    className="mb-4 mt-2"
                  >
                    LÄS MER OM HUR VERKTYGET FUNGERAR
                  </Button>
                </Link>
                {user && user.username && (
                  <Link
                    className="d-block mb-5"
                    to={
                      "./organizations/" +
                      user.organizationId +
                      "/current-survey/introduction"
                    }
                  >
                    <Button variant="action" className="mb-4">
                      {text.HOME.PÅBÖRJA_KARTLÄGGNING}
                    </Button>
                  </Link>
                )}
              </Col>
            </Row> */}
          </Container>
        )}
      </Container>
    </div>
  );
};

export default Home;
