import { useParams, Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import useUser from "services/useUser";
import "./styles.scss";

const Step = () => {
  const params = useParams();
  let user = useUser();

  const getNextUrl = () => {
    if (params.n < 4) {
      return "/step/" + (parseInt(params.n) + 1);
    } else {
      if (user && user.organizationId) {
        return `/organizations/${user.organizationId}/current-survey/introduction`;
      } else {
        return "/register";
      }
    }
  };

  const getNextButtonCopy = () => {
    if (params.n < 4) {
      return "NÄSTA";
    } else if (user && user.organizationId) {
      return "PÅBÖRJA KARTLÄGGNING";
    } else {
      return "REGISTRERA";
    }
  };

  return (
    <div className="step route-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        <Container>
          <Row className="justify-content-center">
            <Col className="mb-5">
              {params.n === "1" && (
                <>
                  <h1>Samla deltagare</h1>
                  <p>
                    Innan ni genomför er nulägesanalys är det viktigt att samla
                    och engagera de deltagare från er organisation som behövs
                    för att besvara frågorna på ett bra sätt. Nulägesanalysen
                    berör olika delar av er organisation och ofta behövs olika
                    deltagare, kompetenser och roller för att genomföra
                    analysen.
                  </p>
                  <p>
                    Inte sällan kan vissa områden och frågor bara besvaras av
                    vissa roller eller kompetenser, som ledningen eller
                    ekonomiavdelningen. Eller att de faktiskt behöver besvaras
                    av en bredd av anställda för att få en realistisk bild av
                    organisationens nuläges. Frågornas område och er
                    organisatoriska struktur kommer avgöra vilka deltagare som
                    är viktiga att ha med för just er. Titta igenom frågorna och
                    fundera över vilka deltagare, roller och kompetenser som är
                    viktiga för att ni ska få ett bra resultat och förankringen
                    i organisationen.
                  </p>
                  <p>
                    Kompetenser och roller som kan vara relevanta i olika delar
                    av kartläggningen är t ex: projektledare, förvaltare,
                    upphandlings/inköpsansvarig, IT-förvaltning,
                    digitaliseringsansvariga/strateger/experter.
                  </p>
                </>
              )}
              {params.n === "2" && (
                <>
                  <h1>Skapa en gemensam förståelse</h1>
                  <p>
                    Nu när ni har samlat de deltagare som ska genomföra
                    nulägesanalysen är det viktigt att ni får en gemensam
                    förståelse av varför ni ska genomföra en nulägesanalys. Vad
                    det innebär för er och vad ni kommer göra med ert resultat.
                    Ett tips kan vara att gå igenom denna introduktion
                    tillsammans innan ni påbörjar det faktiska arbetet med
                    analysen.
                  </p>
                  <p>
                    Verktyget ska stärka er förmåga till digitalisering genom
                    att belysa nuläget för er digitala mognad, men
                    digitalisering själv är inte ändamålet. Digitalisering är
                    ett verktyg som hjälper en organisation att åstadkomma andra
                    mål och digital mognad är ett mått på organisationens
                    förmåga att ändamålsenligt utnyttja digitaliseringens
                    möjligheter. Ni behöver också skapa er en gemensam bild av
                    vad ni vill uppnå med digitalisering. Verktyget kommer
                    inledningsvis guida er att svara på den frågan och bidra
                    till att skapa en sådan förståelse.
                  </p>
                </>
              )}
              {params.n === "3" && (
                <>
                  <h1>Genomför nulägesanalysen</h1>
                  <p>
                    När ni har samlat relevanta deltagare och skapat en gemensam
                    förståelse är det dags att faktiskt genomföra
                    nulägesanalysen. Kul!
                  </p>
                  <p>
                    Sätt er gemensamt och svara på frågorna vid några tillfällen
                    med tid för diskussion och reflektion. Ta det som en
                    gemensam övning i samband med att hela organisationen möts
                    eller dela upp arbetet i mindre biter över tid. Själva
                    analysen kan genomföras på olika sätt och så som det passar
                    er organisation bäst. Era svar kan sparas och ni kan
                    fortsätta igen när det passar.
                  </p>
                  <p>
                    Tänk på att inte enskilda personer besvarar majoriteten av
                    frågorna, då detta kan skapa en skev bild av organisationens
                    nuläge. Säkerställ att frågorna faktiskt får en bredd av
                    perspektiv från organisationen, så att det ger en relevant
                    överblick av ert nuläge. Olika frågor och områden kan
                    besvaras av olika kompetenser och roller, men det är alltid
                    bra att ha någon eller några som leder och håller ihop
                    helheten. Detta ger er bäst förutsättningar att ta ert
                    arbete med digitalisering vidare i nästa steg.
                  </p>
                </>
              )}
              {params.n === "4" && (
                <>
                  <h1>Tolka ert resultat</h1>
                  <p>
                    När ni har genomfört nulägesanalysen får ni ett samlat
                    resultat i en strukturerad överblick som visar inom vilka
                    områden ni är starka respektive svaga. Ni kommer även få era
                    svar samlade per område för att enkelt och smidigt få en
                    bild av hur ni har resonerat och svarat. Det kan hjälpa er
                    att se varför ni är starka respektive svaga inom ett område.
                    Resultatet är inte en diagnos och ger ingen receptlista för
                    ert nästa steg, men det ger er en samlad bild och ett
                    underlag att diskutera vidare.
                  </p>
                  <p>
                    Diskussionerna började säkerligen redan under arbetet med
                    nulägesanalysen, så nu är det viktigt att ni gemensamt
                    tolkar ert resultat och vad det innebär för just er
                    organisation och fortsätta utvecklingsarbetet.
                  </p>
                  <p>
                    Resultatet ger er en bra möjlighet att prioritera vilka steg
                    som är viktiga för er att ta och vad som är målet med
                    digitalisering och ökad digital mognad för just er
                    organisation.
                  </p>
                </>
              )}
            </Col>
            <Col>
              <div className="step-image d-flex align-items-center justify-content-center mt-5">
                {params.n === "1" && <img src="/step1.svg" />}
                {params.n === "2" && <img src="/step2.svg" />}
                {params.n === "3" && <img src="/step3.svg" />}
                {params.n === "4" && <img src="/step4.svg" />}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Link to={getNextUrl()}>
                <Button variant="action" className="mb-4">
                  {getNextButtonCopy()}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Step;
