import http from "utils/http";
import { useState, useEffect } from "react";
import useInvitation from "./useInvitation";

function useOrganization(organizationId) {
  const [org, setOrg] = useState([]);
  const [invitationFailed, postInvitation] = useInvitation();

  useEffect(() => {
    getOrg();
  }, []);

  async function getOrg() {
    const response = await http.get("/api/organizations/" + organizationId);
    setOrg(response.data);
  }

  async function addOrgEditor() {
    const email = prompt("Lägg till en ny redigerares email");
    if (email) {
      await http.post("/api/organizations/" + organizationId + "/editors", {
        email: email.toLowerCase(),
      });
    }
    await getOrg();

    //FIXME: Make these strings dynamic NO MOVE TO BACKEND
    await postInvitation(
      "EDITOR",
      email,
      "Inbjudning till Digitaliseringsradarn",
      "COPY"
    );
  }

  async function removeOrgEditor(editor) {
    if (window.confirm("Är du säker?")) {
      await http.remove(
        "/api/organizations/" + organizationId + "/editors/" + editor._id
      );
    }
    await getOrg();
  }

  return [org, addOrgEditor, removeOrgEditor];
}

export default useOrganization;
