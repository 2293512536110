import http from "utils/http";
import { useState, useEffect } from "react";
import useInvitation from "./useInvitation";

function useOrganizations() {
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    getOrgs();
  }, []);

  async function getOrgs() {
    const response = await http.get("/api/organizations");
    setOrgs(response.data);
  }

  async function addOrg(org) {
    if (org) {
      const response = await http.post("/api/organizations/", {
        anid: org.anid,
        name: org.name,
        administrators: [],
        editors: [],
      });
      setOrgs([...orgs, response.data]);
    }
  }

  async function removeOrg(org) {
    if (window.confirm("Är du säker?")) {
      await http.remove("/api/organizations/" + org._id);
      setOrgs(orgs.filter((a) => a._id !== org._id));
    }
  }

  async function addOrgAdmin(org, username, password) {
    console.log(org, username, password);
    await http.post("/api/organizations/" + org._id + "/admins", {
      username,
      password,
    });
    await getOrgs();
  }
  // async function addOrgAdmin(org, postInvitation) {
  //   const email = prompt("Lägg till adminitratörs email");
  //   if (email) {
  //     await http.post("/api/organizations/" + org._id + "/admins", {
  //       email: email.toLowerCase(),
  //     });
  //   }
  //   await getOrgs();

  //   //FIXME: Make these strings dynamic
  //   await postInvitation(
  //     "ORG_ADMIN",
  //     email,
  //     "Inbjudning till digitaliseringsradarn",
  //     "COPY"
  //   );
  // }

  async function removeOrgAdmin(org, admin) {
    if (window.confirm("Är du säker?")) {
      await http.remove(
        "/api/organizations/" + org._id + "/admins/" + admin._id
      );
    }
    await getOrgs();
  }

  async function addOrgEditor(org, postInvitation) {
    const email = prompt("Lägg till en ny medarbetares email");
    if (email) {
      await http.post("/api/organizations/" + org._id + "/editors", {
        email: email.toLowerCase(),
      });
    }
    await getOrgs();

    //FIXME: Make these strings dynamic
    await postInvitation(
      "EDITOR",
      email,
      "Inbjudning till redaktörsroll",
      "COPY"
    );
  }

  async function removeOrgEditor(org, editor) {
    if (window.confirm("Är du säker?")) {
      await http.remove(
        "/api/organizations/" + org._id + "/editors/" + editor._id
      );
    }
    await getOrgs();
  }

  return [
    orgs,
    addOrg,
    removeOrg,
    addOrgAdmin,
    removeOrgAdmin,
    addOrgEditor,
    removeOrgEditor,
  ];
}

export default useOrganizations;
