import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const AddOrgForm = (props) => {
  const { addOrg } = props;

  const [name, setName] = useState("");
  const [id, setId] = useState("");

  return (
    <Form className="ps-2 d-flex align-items-baseline">
      <Row>
        <Col>
          <Form.Control
            placeholder="Organisationsnamn"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{" "}
        </Col>
        {/* <Col>
          <Form.Control
            placeholder="AN id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </Col> */}
        <Col>
          <Button
            className="btn-sm"
            onClick={() => {
              if (name) {
                const newOrg = {
                  anid: id,
                  name: name,
                  administrators: [],
                  editors: [],
                };
                addOrg(newOrg);
              }
            }}
          >
            Lägg till organisation
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddOrgForm;
