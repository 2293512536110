import { QuestionSelectBase } from "survey-react";

const ids = [
  {
    id: "qAbilities",
    type: "single-matrix",
    rows: [
      "aIndividualIdentification",
      "aDigitalSignature",
      "aDigitalContracts",
      "aAccessManagement",
      "aApplianceId",
      "aIndependentIssueManagement",
      "aTenantPlattform",
      "aGenericDocumentHandling",
      "aBIM",
    ],
  },
  {
    id: "qGeneralAbilities",
    type: "single-matrix",
    rows: [
      "aIntegrationPlatform",
      "aDataPlatform",
      "aApiPlatform",
      "aEventDrivenArchitecture",
      "aIotAutomation",
      "aAiAdvancedAnalysis",
      "aRpaWorkflow",
    ],
  },
  {
    id: "qInformationDataControl",
    type: "single-matrix",
    rows: [
      "aMasterdata",
      "aDataQuality",
      "aApiSpecs",
      "aDatadrivetDecisions",
      "aInformationClassification",
      "aVisualisations",
    ],
  },
  {
    id: "qITMaintenance",
    type: "radio",
  },
  {
    id: "qITRoles",
    type: "checkbox",
  },
  {
    id: "qDevelopmentWoW",
    type: "radio",
  },
  {
    id: "qAddingNewServices",
    type: "checkbox",
  },
  {
    id: "qProcurement",
    type: "checkbox",
  },
  {
    id: "qProcurementType",
    type: "radio",
  },
  {
    id: "qSecurityCompetence",
    type: "rating",
  },
  {
    id: "qDataDrivenDecisions",
    type: "radio",
  },
  {
    id: "qInnovationStrategy",
    type: "checkbox",
  },
  {
    id: "qInnovationStrategyResources",
    type: "checkbox",
  },
  {
    id: "qBizDev",
    type: "checkbox",
  },
  {
    id: "qProjectManagement",
    type: "radio",
    hasOther: true,
  },
  {
    id: "qDigitizing",
    type: "radio",
  },
  {
    id: "qCooperation",
    type: "radio",
  },
  {
    id: "qRealEstateSystem",
    type: "radio",
    hasOther: true,
  },
  {
    id: "qFastAPI",
    type: "radio",
  },
  {
    id: "qInfrastructure",
    type: "checkbox",
  },
  {
    id: "qExternalWeb",
    type: "radio",
    hasOther: true,
  },
  {
    id: "qOperatingSystems",
    type: "radio",
    hasOther: true,
  },
  {
    id: "qContainers",
    type: "radio",
  },
  {
    id: "qIntegrationPlatforms",
    type: "checkbox",
    hasOther: true,
  },
  {
    id: "qInfrastructureSatisfaction",
    type: "rating",
  },
  {
    id: "qStrategicAreaDigitalServices",
    type: "radio-single-matrix",
    matrixId: "qNeedsDigitalServices",
    rows: ["aSharingServices", "aAlarmServices", "aMobilityServices"],
  },
  {
    id: "qStrategicTenantCommunication",
    type: "radio-single-matrix",
    matrixId: "qNeedsTenantCommunication",
    rows: [
      "aTenantNewsletters",
      "aProactiveCommunication",
      "aTenantApp",
      "aStairwellScreen",
      "aTenantSatisfaction",
      "aCitizenDialog",
      "aChatBot",
    ],
  },
  {
    id: "qStrategicRentalProcess",
    type: "radio-single-matrix",
    matrixId: "qNeedsRentalProcess",
    rows: [
      "aDigitalExhibit",
      "aDigitalKeys",
      "aDigitalContracSigning",
      "aDigitalInspection",
      "aDigitalTenantScreening",
      "aDigitalIntroduktion",
      "aDigitalOptionsHandling",
    ],
  },
  {
    id: "qStrategicEnergyIMD",
    type: "radio-single-matrix",
    matrixId: "qNeedsIMD",
    rows: [
      "aCollectingMeasures",
      "aSmartEnergyControl",
      "aIMD",
      "aEnergyFollowUp",
      "aPowerEqualization",
      "aPowerFlexibility",
    ],
  },
  {
    id: "qStrategicCooperation",
    type: "radio-single-matrix",
    matrixId: "qNeedsCooperation",
    rows: [
      "aBlueLight",
      "aHealthServices",
      "aIdeaBusiness",
      "aChargingInfrastructure",
      "aDeliveryBoxes",
      "aDeliveries",
      "aPrivateBusiness",
      "aKOModel",
    ],
  },
  {
    id: "qStrategicMaintenance",
    type: "radio-single-matrix",
    matrixId: "qNeedsMaintenance",
    rows: [
      "aAccessEntrepreneur",
      "aMaintenancePlan",
      "aSignMaintenanceAction",
      "aGaranteeHandling",
      "aMaintenanceFollowup",
    ],
  },
  {
    id: "qStrategicRepair",
    type: "radio-single-matrix",
    matrixId: "qNeedsRepair",
    rows: [
      "aErrorReportQR",
      "aPredictiveErrorAction",
      "aAutomaticFeedback",
      "aIntegrateEntrepreneur",
    ],
  },
];

const getCompletionStatus = (org) => {
  if (org.surveys.length === 0) {
    return "";
  }
  if (org.surveys[org.surveys.length - 1].response) {
    const resp = org.surveys[org.surveys.length - 1].response;
    let totalCount = 0;
    let answeredCount = 0;
    ids.forEach((question) => {
      if (question.type === "rating") {
        totalCount++;
        if (resp[question.id]) {
          answeredCount++;
        }
      } else if (question.type === "radio" || question.type === "checkbox") {
        totalCount++;
        if (resp[question.id] || resp[question.id + "-Comment"]) {
          answeredCount++;
        }
      } else if (question.type === "single-matrix") {
        question.rows.forEach((row) => {
          totalCount++;
          if (resp[question.id] && resp[question.id][row]) {
            answeredCount++;
          }
        });
      } else {
        // radio-single-matrix
        if (resp[question.id]) {
          question.rows.forEach((row) => {
            totalCount++;
            if (resp[question.matrixId] && resp[question.matrixId][row]) {
              answeredCount++;
            }
          });
        }
      }
    });
    let progress =
      "Genomförd till " + Math.round((answeredCount / totalCount) * 100) + "%";
    if (org.surveys[org.surveys.length - 1].completed) {
      progress +=
        " - " +
        (org.surveys[org.surveys.length - 1].completed + "")
          .substring(0, 16)
          .replace("T", " ");
    }
    return progress;
  }
  return "Ej slutfört";
};

const getSurveyCompletionDate = (survey) => {
  if (survey.completed) {
    return (survey.completed + "").substring(0, 16).replace("T", " ");
  }
  if (survey.response) {
    if (
      survey.response["qNeedsDigitalServices"] ||
      survey.response["qNeedsRentalProcess"] ||
      survey.response["qNeedsIMD"] ||
      survey.response["qNeedsCooperation"] ||
      survey.response["qNeedsMaintenance"] ||
      survey.response["qNeedsRepair"] ||
      survey.response["qNeedsTenantCommunication"]
    ) {
      return "Datum saknas";
    }
  }
  return "Ej slutfört";
};

export { getCompletionStatus, getSurveyCompletionDate };
