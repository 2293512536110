import http from "utils/http";
import { useState, useEffect } from "react";
import useInvitation from "./useInvitation";

function useSystemAdmins() {
  const [systemAdmins, setSysAdmins] = useState([]);
  const postInvitation = useInvitation();

  useEffect(() => {
    getSystemAdmins();
  }, []);

  async function getSystemAdmins() {
    const response = await http.get("/api/system-administration");
    setSysAdmins(response.data);
  }

  async function addSystemAdmin() {
    const email = prompt("Lägg till admin email");
    if (email) {
      const response = await http.post("/api/system-administration", {
        email: email.toLowerCase(),
      });
      setSysAdmins([...systemAdmins, response.data]);
    }
  }

  async function removeSystemAdmin(admin) {
    if (window.confirm("Är du säker?")) {
      await http.remove("/api/system-administration/" + admin._id);
      setSysAdmins(systemAdmins.filter((a) => a._id !== admin._id));
    }
  }

  return [systemAdmins, addSystemAdmin, removeSystemAdmin];
}

export default useSystemAdmins;
