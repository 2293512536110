import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import http from "../../utils/http";

const Register = () => {
  const usernameRef = useRef();
  const navigate = useNavigate();

  const handleRegister = async (evt) => {
    evt.preventDefault();
    await http.post("/register", { email: usernameRef.current.value });
    window.alert(
      "Tack för din registering. Vi återkommer med inloggningsuppgifter."
    );
    navigate("/");
  };

  return (
    <div className="register route-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        <Container>
          <Row>
            <Col className="mb-5 form-column">
              <h1 className="text-white">Registera</h1>

              <p className="text-white">
                Att få inloggningsuppgifter till digitaliseringsradarn för
                byggherrar är gratis. Maila till{" "}
                <a
                  className="text-white"
                  href="mailto:saskia@foginnovation.se?subjecct=digitaliseringsradarn"
                >
                  saskia@foginnovation.se
                </a>{" "}
                för att få ett inlog.
              </p>
              {/* <Form className="mb-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" placeholder="" ref={usernameRef} />
                <Button onClick={handleRegister}>Registera</Button>
              </Form.Group>
            </Form> */}
            </Col>
            <Col>
              <Col className="position-relative">
                <img className="position-absolute" src="squares2.png" />
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Register;
