import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import useText from "services/useText";
import useSurvey from "services/useSurvey";

import Login from "../Login";
import Home from "../Home";
import RadarHome from "../RadarHome";
import useUser from "../../services/useUser";
import SystemAdministration from "../SystemAdministration";
import RadarPage from "../RadarPage";
import CurrentSituation from "../CurrentSituation";
import OrganizationAdministration from "../OrganizationAdministration";
import OrganizationSelfAdministration from "../OrganizationSelfAdministration";
import ComparePage from "../ComparePage";
import NextStep from "../NextStep";
import Register from "../Register";
import Step from "../Step";
import IntroSteps from "../IntroSteps";
import navbarItems from "./components/navbarItems";
import Footer from "./components/footer";
import ReactGA from "react-ga4";
import Privacy from "routes/Privacy";
import About from "routes/About";
import "./styles.scss";
import NotifyUpdate from "components/NotifyUpdate";

const App = () => {
  let user = useUser();
  const text = useText();
  const survey = useSurvey();
  let location = useLocation();

  const logoutUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api/logout"
      : "/api/logout";

  const loginUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/signin"
      : "/signin";

  function RequireAuth({ children }) {
    return user.role !== "NOTLOGGEDIN" ? children : <Navigate to={"/signin"} />;
  }

  const getSizeClass = () => {
    if (
      location.pathname === "/" ||
      location.pathname === "/digiradar-start" ||
      location.pathname === "/about" ||
      location.pathname === "/register" ||
      location.pathname === "/signin" ||
      location.pathname.indexOf("/step/") !== -1 ||
      location.pathname === "/introsteps"
    ) {
      return "big-logo";
    } else {
      return "";
    }
  };

  return (
    <div
      className="App d-flex flex-column vh-100"
      style={{
        backgroundImage: "url(/byggherre-background.jpg)",
        backgroundSize: "180%",
        backgroundPositionX: "-450px",
      }}
    >
      {user && text && survey && (
        <>
          <div className="nav-menu">
            <Container>
              <Row>
                <Col className="d-flex justify-content-between">
                  {location.pathname !== "/" && (
                    <div
                      className="d-flex justify-content-start"
                      style={{ marginLeft: -15 }}
                    >
                      {navbarItems.HowItWorks(user.role)}
                      {navbarItems.CurrentSituation(
                        user.role,
                        user.organizationId
                      )}
                      {navbarItems.Radar(user.role, user.organizationId)}
                      {navbarItems.OrgAdmin(user.role)}
                      {navbarItems.Comparison(user.role, user.organizationId)}
                      {navbarItems.NextStep(user.role, user.organizationId)}
                      {navbarItems.AboutStep(user.role)}
                    </div>
                  )}
                  {location.pathname === "/" && (
                    <div
                      className="d-flex justify-content-start"
                      style={{ marginLeft: -15 }}
                    >
                      {navbarItems.DigiRadar(user.role)}
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    {navbarItems.LoginLogout(user, loginUrl, logoutUrl)}
                    {navbarItems.Register(user)}
                  </div>
                </Col>
              </Row>
              <Row className="d-none d-md-flex">
                {/* <Col>
                  <h1 className="menu-title">Digitaliseringsradarn</h1>
                </Col> */}
                <Col className="d-flex justify-content-end align-items-top mt-5">
                  <Link
                    to="/"
                    style={{ position: "relative", height: 70, width: 200 }}
                  >
                    <img
                      src="/byggherrelyftet_logo.png"
                      style={{ width: 200, position: "absolute", right: -20 }}
                    />
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <NotifyUpdate />

          <Routes>
            <Route
              path="/digiradar-start"
              element={
                <div role="main">
                  <RadarHome />
                </div>
              }
            />
            <Route
              path="/"
              element={
                <div role="main">
                  <Home />
                </div>
              }
            />
            <Route
              path="/register"
              element={
                <div role="main">
                  <Register />
                </div>
              }
            />
            <Route
              path="/introsteps"
              element={
                <div role="main">
                  <IntroSteps />
                </div>
              }
            />
            <Route
              path="/step/:n"
              element={
                <div role="main">
                  <Step />
                </div>
              }
            />
            <Route
              path="/system-administration"
              element={
                <RequireAuth>
                  <div role="main">
                    <SystemAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organization"
              element={
                <RequireAuth>
                  <div role="main">
                    <OrganizationAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/digitizing-group"
              element={
                <RequireAuth>
                  <div role="main">
                    <OrganizationSelfAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/current-survey/*"
              element={
                <RequireAuth>
                  <div role="main">
                    <CurrentSituation />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/organizations/:organizationId/radar"
              element={
                <RequireAuth>
                  <div role="main">
                    <RadarPage />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/compare/*"
              element={
                <RequireAuth>
                  <div role="main">
                    <ComparePage />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/next-step"
              element={
                <RequireAuth>
                  <div role="main">
                    <NextStep />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/signin"
              element={
                <div role="main">
                  <Login />
                </div>
              }
            />
            <Route
              path="/about"
              element={
                <div role="main">
                  <About />
                </div>
              }
            />
            <Route
              path="/privacy"
              element={
                <div role="main">
                  <Privacy />
                </div>
              }
            />
          </Routes>
          {/* <Footer hide={location.pathname === "/signin"} user={user} /> */}
        </>
      )}
    </div>
  );
};

export default App;
