import { Col, Container, Row } from "react-bootstrap";
import useText from "../../services/useText";
import HTMLText from "components/HTMLText";
import "./style.scss";

const NextStep = () => {
  const text = useText();
  return (
    <div className="next-step route-page">
      <Container fluid className="content-container">
        <img src="/squares1.png" className="squares-left" />
        <Container>
          <Row>
            <Col className="mb-5 col-md-8">
              <h1>Nästa steg</h1>
              <p>
                Förhoppningsvis ledde nulägesanalysen och tolkningen av ert
                resultat till spännande diskussioner och nya insikter. Och
                kanske har ni redan prioriterat vad som är viktigt för just er
                att jobba vidare med? Om inte, kan det vara bra att göra det nu.
                Genom att identifiera och prioritera vilka åtgärder som behövs
                på kort och lång sikt får ni ett fokus för det fortsatta
                arbetet.
              </p>
              <p>
                Era prioriteringar ska fungera som vägledning för arbetet, men
                för att de ska bli riktigt bra att styra efter behöver de
                formuleras som mål. Vad ska ni uppnå? Och vad är ett rimligt,
                men utmanande mål för er?
              </p>
              <p>
                Efter en beskrivning av nuläget och era prioriteringar och mål
                är nästa steg att göra en handlingsplan.
                <br />
                Hur tar ni er från nuläget till målet?
                <br />
                Vem eller vilka behöver involveras och ta beslut?
                <br />
                Vilka hinder finns? Och vilka steg måste tas i vilken ordning?
              </p>
              <p>
                Behöver ni mer information och stöd finns det många som gärna
                hjälper till. Börja till exempel med att titta på
                branschorganisationer och deras initiativ:
              </p>
              <p>
                <a href="https://www.byggherre.se/">
                  https://www.byggherre.se/
                </a>
                <br />
                <a href="https://www.smartbuilt.se/">
                  https://www.smartbuilt.se/
                </a>
                <br />
                <a href="https://bimalliance.se/">https://bimalliance.se/</a>
                <br />
                <a href=" https://www.nationella-riktlinjer.se/">
                  https://www.nationella-riktlinjer.se/
                </a>
                <br />
                <a href="https://beast.se/"></a>
              </p>
              <p>
                Passa också på att titta på webinarieserien vi organiserade inom
                Byggherrelyftet tillsammans med föreningen Byggherrarna:
              </p>
              <p>
                <a href="https://www.byggherre.se/utbildning/en-digitalt-mogen-byggherre">
                  https://www.byggherre.se/utbildning/en-digitalt-mogen-byggherre
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default NextStep;
