import http from "utils/http";
import { useState, useEffect } from "react";

function useCurrentSurvey(organizationId) {
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    getCurrentSurvey();
  }, []);

  async function getCurrentSurvey() {
    try {
      const response = await http.get(
        "/api/organizations/" + organizationId + "/current-survey"
      );
      setSurvey(response.data);
    } catch (err) {
      const response = await http.post(
        "/api/organizations/" + organizationId + "/surveys"
      );
      setSurvey(response.data);
    }
  }

  const saveSurveyResponse = async (surveyResponse, completed) => {
    survey.response = surveyResponse;
    if (completed) {
      survey.completed = new Date().toISOString();
    }
    const response = await http.put(
      "/api/organizations/" + organizationId + "/surveys",
      survey
    );
    if (response.status !== 200) {
      throw "Failed to save survey response";
    }
    survey._version = response.data.version;
  };

  return [survey, saveSurveyResponse];
}

export default useCurrentSurvey;
