import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Survey from "survey-react";
import useCurrentSurvey from "services/useCurrentSurvey";
import { useParams, Link, useSearchParams } from "react-router-dom";
import PageProgress from "components/PageProgress";
import useSurveyModel from "services/useSurveyModel";
import useText from "services/useText";
import tippy from "tippy.js";
import "./style.scss";
import "survey-react/survey.css";
import "tippy.js/dist/tippy.css";

Survey.StylesManager.applyTheme("orange");
Survey.Serializer.addProperty("question", "tooltips:array");
Survey.Serializer.addProperty("question", "stateTooltips:array");

const SurveyPages = () => {
  const text = useText();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [survey, saveSurveyResponse] = useCurrentSurvey(params.organizationId);
  const model = useSurveyModel();
  const [page, setPage] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const progressElement = useRef();

  useEffect(() => {
    if (survey && model) {
      model.data = survey.response || {};
      model.focusFirstQuestionAutomatic = false;
      const pageIndex = searchParams.get("page");
      if (pageIndex) {
        setPage(Number(pageIndex));
        model.currentPage = Number(pageIndex);
      }
    }
  }, [survey, model]);

  const changePage = (i) => {
    console.log("change page", i);
    setPage(i);
    model.focusFirstQuestionAutomatic = false;
    model.currentPage = i;
  };

  const surveyComplete = async (survey, options) => {
    await saveSurveyResponse(survey.data, true);
    navigate("./completed");
  };

  const currentPageChanged = async (survey, options) => {
    if (options.isNextPage || options.isPrevPage) {
      try {
        setPage(page + (options.isNextPage ? 1 : -1));
        await saveSurveyResponse(survey.data, false);
        setIsDirty(false);
        setTimeout(() => {
          progressElement.current.scrollIntoView();
        }, 600);
      } catch (err) {
        alert(
          "Du har inte senaste versionen av enkäten. Den laddas med automatik."
        );
        window.location.reload(false);
      }
    } else {
      setPage(survey.currentPageNo);
    }
  };

  const saveSurvey = async () => {
    await saveSurveyResponse(model.data, false);
    setIsDirty(false);
    window.alert("Enkäten har sparats!");
  };

  const addInfoButton = (title, tooltipClass) => {
    let node = document.createElement("i");
    node.className = "stooltip " + tooltipClass;
    node.innerHTML = "<img class='info-icon' src='/info.svg' />";
    title.appendChild(node);
    return node;
  };

  const prependTitle = (node, title) => {
    let newNode = document.createElement("span");
    newNode.className = "resourceTitleGroup";
    newNode.innerHTML = title;
    node.prepend(newNode);
  };

  const afterRenderQuestion = (survey, options) => {
    if (options.question.tooltips) {
      const title = options.htmlElement.querySelectorAll("h5");
      const infoButton = addInfoButton(title[0], "stooltip-matrix");
      tippy(infoButton, {
        content: options.question.tooltips[0],
        allowHTML: true,
      });
    }

    // const titles = options.htmlElement.querySelectorAll(
    //   "tr td.sv_q_m_cell:first-child"
    // );
    // if (titles.length > 0) {
    //   titles.forEach((title, i) => {
    //     const infoButton = addInfoButton(title, "stooltip-matrix");
    //     tippy(infoButton, {
    //       content: options.question.tooltips
    //         ? options.question.tooltips[i]
    //         : "",
    //       allowHTML: true,
    //     });
    //   });
    //   // Add state info
    //   const states = options.htmlElement.querySelectorAll(
    //     ".sv_q_matrix th .sv-string-viewer"
    //   );
    //   states.forEach((state, i) => {
    //     const infoButton = addInfoButton(state, "stooltip-state");
    //     tippy(infoButton, {
    //       content: options.question.stateTooltips[i],
    //       allowHTML: true,
    //     });
    //   });
    // }

    // const checkBoxTitles = options.htmlElement.querySelectorAll(
    //   ".sv_q_checkbox_label"
    // );
    // if (checkBoxTitles.length === 1) {
    //   const infoButton = addInfoButton(checkBoxTitles[0], "stooltip-checkbox");
    //   tippy(infoButton, {
    //     content: options.question.tooltips[0],
    //     allowHTML: true,
    //   });
    // }

    // if (options.question.propertyHash.name === "qInnovationStrategyResources") {
    //   const questions = options.htmlElement.querySelectorAll(
    //     ".sv_q_checkbox_label"
    //   );
    //   if (questions.length > 0) {
    //     questions.forEach((question, i) => {
    //       const infoButton = addInfoButton(question, "stooltip-checkbox");
    //       tippy(infoButton, {
    //         content: options.question.tooltips[i],
    //         allowHTML: true,
    //       });
    //     });
    //   }
    // }

    // if (
    //   options.question.propertyHash.name === "qDataDrivenDecisions" ||
    //   options.question.propertyHash.name === "qContainers"
    // ) {
    //   const questions = options.htmlElement.querySelectorAll(".sv_q_title");
    //   if (questions.length === 1) {
    //     const infoButton = addInfoButton(questions[0], "stooltip-checkbox");
    //     tippy(infoButton, {
    //       content: options.question.tooltips[0],
    //       allowHTML: true,
    //     });
    //   }
    // }

    // if (options.question.propertyHash.name === "qITRoles") {
    //   const alternatives =
    //     options.htmlElement.querySelectorAll(".sv_q_checkbox");
    //   prependTitle(alternatives[0], "Ledningsnivå:");
    //   prependTitle(alternatives[4], "Enhetsnivå:");
    //   prependTitle(alternatives[7], "Specialistkompetens:");
    //   prependTitle(alternatives[14], "Team-/projektnivå:");
    // }
  };

  return (
    <div className="CurrentSurvey">
      <div className="container">
        <div className="row">
          <div className="col pt-4 mb-4">
            <div className="d-flex justify-content-between">
              <h1>{text.ENKÄT.TITLE}</h1>
              <Button
                onClick={saveSurvey}
                className="align-self-center align-self-center"
                variant="hollow"
                disabled={!isDirty}
              >
                Spara enkäten
              </Button>
              {/* <Link
                to={"./../digitizing-group"}
                className="align-self-center m-4"
              >
                {text.ENKÄT.BJUD_IN}
              </Link> */}
            </div>
            <div ref={progressElement} />
            <PageProgress activeStep={page} onClick={changePage} />
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12">
            {survey && model && (
              <Survey.Survey
                model={model}
                focusFirstQuestionAutomatic={false}
                onComplete={(survey, options) =>
                  surveyComplete(survey, options)
                }
                onCurrentPageChanged={(survey, options) =>
                  currentPageChanged(survey, options)
                }
                onAfterRenderQuestion={(survey, options) =>
                  afterRenderQuestion(survey, options)
                }
                onValueChanged={() => setIsDirty(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyPages;
