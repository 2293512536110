import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./styles.scss";

const ConditionalNavLink = (props) => {
  const { roleConditional, to, icon, text, activeRoute } = props;
  const location = useLocation();

  console.log(activeRoute);
  return (
    roleConditional && (
      <NavLink
        className={
          "nav-link conditional-nav-link py-4 " +
          (activeRoute.some((r) => location.pathname.indexOf(r) !== -1)
            ? "active"
            : "")
        }
        role="menuitem"
        to={to}
      >
        {icon}
        {text}
      </NavLink>
    )
  );
};

export default ConditionalNavLink;
