import { useState, useEffect, useRef, forwardRef } from "react";
// import useRadars from "services/useRadar";
// import RadarChart from "components/RadarChart";
import PolarChart from "components/PolarChart";
import { useParams } from "react-router-dom";
import useIndicators from "services/useIndicators";
import useOrganizationResponse from "services/useOrganizationResponse";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import useText from "services/useText";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import useSurvey from "services/useSurvey";
import { useReactToPrint } from "react-to-print";
import "./style.scss";

const Answer = ({ question, answer }) => {
  if (Array.isArray(answer)) {
    return (
      <ul className="answer d-block mb-2">
        {answer.map((item, i) => {
          return (
            <li key={i}>
              {question.choices.find((c) => c.value === item).text["sv"]}
            </li>
          );
        })}
      </ul>
    );
  } else if (question.type === "radiogroup") {
    return (
      <span className="answer d-block mb-2">
        {answer
          ? question.choices.find((c) => c.value === answer).text["sv"]
          : "-"}
      </span>
    );
  } else if (question.type === "rating") {
    return (
      <span className="answer d-block mb-2">
        {answer ? answer + " av 5" : "-"}
      </span>
    );
  } else if (question.type === "matrix") {
    return (
      <table>
        {question.rows.map((row, i) => {
          return (
            <tr key={"row" + i}>
              <td>{row.text.sv}</td>
              <td>
                {answer
                  ? question.columns.find(
                      (c) => c.value === Object.values(answer)[i]
                    )["text"]["sv"]
                  : "-"}
              </td>
            </tr>
          );
        })}
      </table>
    );
  }
  return <span className="answer d-block mb-2">{JSON.stringify(answer)}</span>;
};

export const RadarPrint = forwardRef(
  ({ indicatorsData, survey, orgResponse, text }, ref) => {
    return (
      <div ref={ref} className="radar-print">
        <h1>Resultat av er nulägesanalys</h1>
        <PolarChart data={indicatorsData} />
      </div>
    );
  }
);

export const AnswersPrint = forwardRef(
  ({ indicatorsData, survey, orgResponse, getPageInfo }, ref) => {
    return (
      <div ref={ref} className="answers-print">
        <ul>
          {survey.pages.map((page, i) => (
            <li>
              <h3 className="fw-bold">{page.title["sv"]}</h3>
              <p className="accordeon-page-info">{getPageInfo(i)}</p>
              <h4 className="fw-bold">Mina svar</h4>
              <ul>
                {page.elements
                  .filter((e) => e.type !== "html")
                  .map((element) => (
                    <>
                      <li>{element.title["sv"]}</li>
                      <Answer
                        question={element}
                        answer={orgResponse.response[element.name]}
                      />
                    </>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

const RadarPage = () => {
  const { organizationId } = useParams();
  // const [radars] = useRadars(organizationId);
  const [currentRadarIndex, setCurrentRadarIndex] = useState(0);
  const [indicators, getIndicator] = useIndicators(organizationId);
  const orgResponse = useOrganizationResponse(organizationId, organizationId);
  const survey = useSurvey();
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const accordionRef = useRef(null);

  const radarRef = useRef();
  const answersRef = useRef();
  const handleRadarPrint = useReactToPrint({
    content: () => radarRef.current,
  });
  const handleAnswersPrint = useReactToPrint({
    content: () => answersRef.current,
  });

  const text = useText();
  let indicatorsData = {
    dimensions: [],
  };

  const indictorTitleMap = {
    organizationVisionStrategy:
      text.RADAR.INDIKATOR_LABEL_organizationVisionStrategy,
    organizationManagement: text.RADAR.INDIKATOR_LABEL_organizationManagement,
    organizationCulture: text.RADAR.INDIKATOR_LABEL_organizationCulture,
    organizationCompetence: text.RADAR.INDIKATOR_LABEL_organizationCompetence,
    organizationInnovation: text.RADAR.INDIKATOR_LABEL_organizationInnovation,
    informationInternalProcess:
      text.RADAR.INDIKATOR_LABEL_informationInternalProcess,
    informationInterfaceActors:
      text.RADAR.INDIKATOR_LABEL_informationInterfaceActors,
    productBuildings: text.RADAR.INDIKATOR_LABEL_productBuildings,
  };

  // const sizeOptions = [
  //   { title: "Jämför med alla bolag", value: "0-10000000" },
  //   { title: "0-499 lägenheter", value: "0-499" },
  //   { title: "500-999 lägenheter", value: "500-999" },
  //   { title: "1000-1999 lägenheter", value: "1000-1999" },
  //   { title: "2000-4999 lägenheter", value: "2000-4999" },
  //   { title: "5000-9999 lägenheter", value: "5000-9999" },
  //   { title: "> 10000 lägenheter", value: "10000-10000000" },
  // ];

  // const [companySize, setCompanySize] = useState("all");

  // const companySizeChanged = (size) => {
  //   const [from, to] = size.split("-");
  //   getIndicator(from, to);
  // };

  if (indicators && indicators.length > 0) {
    indicatorsData.dimensions = indicators.map((indicator) => {
      return {
        subject: indictorTitleMap[indicator.indicator],
        score: indicator.score,
        avg: indicator.avg,
      };
    });
  }

  const getPagePercentage = (index) => {
    if (index === 0 || index > 8) {
      return "";
    }
    return " - " + Math.round(indicators[index - 1].score * 100) + "%";
  };

  const getPageInfo = (index) => {
    if (index === 0 || index > 8) {
      return "";
    }
    if (index === 1) {
      return text.RADAR.INDIKATOR_organizationVisionStrategy;
    } else if (index === 2) {
      return text.RADAR.INDIKATOR_organizationManagement;
    } else if (index === 3) {
      return text.RADAR.INDIKATOR_organizationCulture;
    } else if (index === 4) {
      return text.RADAR.INDIKATOR_organizationCompetence;
    } else if (index === 5) {
      return text.RADAR.INDIKATOR_organizationInnovation;
    } else if (index === 6) {
      return text.RADAR.INDIKATOR_informationInternalProcess;
    } else if (index === 7) {
      return text.RADAR.INDIKATOR_informationInterfaceActors;
    } else if (index === 8) {
      return text.RADAR.INDIKATOR_productBuildings;
    }
  };
  // useEffect(() => {
  //   if (radars.length > 0) {
  //     alert("Du måste fylla i ditt nuläga innan du kan få en radarbild");
  //   }
  // }, [radars]);

  return (
    <div className="RadarPage">
      {survey &&
        orgResponse &&
        indicators &&
        indicatorsData.dimensions.length > 0 && (
          <>
            <Container>
              <Row>
                <Col>
                  <h1>Resultat av er nulägesanalys</h1>
                  <p>Grattis, nu har ni kartlagt ert nuläge! </p>
                  <p>
                    Kartläggningen och resultatet ger er underlag för vidare
                    diskussion. Detta är en bra möjlighet att prioritera vilka
                    steg som är viktiga för er att ta vidare. Diagrammet nedan
                    visar hur långt er organisation har kommit inom viktiga
                    aspekter av digitalisering. Beroende på hur långt ni har
                    kommit med arbetet inom dessa områden får ni en indikation
                    på vilka förutsättningar ni har att driva en hållbar
                    digitalisering framåt.
                  </p>
                  <div className="d-flex justify-content-end">
                    <Tippy content={text.RADAR.INDIKATOR_INFO}>
                      <img className="info-icon" src="/info-large.svg" />
                    </Tippy>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PolarChart
                    data={indicatorsData}
                    onLabelClick={(e) => {
                      setActiveAccordionKey(e.index + 2);
                      accordionRef.current.scrollIntoView();
                    }}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center pt-2">
                <Col md={5}>
                  <Link to={"./../next-step"}>
                    <Button variant="action">
                      {text.RADAR.GÅ_TILL_NÄSTA_STEG}
                    </Button>
                  </Link>
                </Col>
                <Col md={5}>
                  <div className="d-flex justify-content-end">
                    <Button variant="hollow" onClick={handleRadarPrint}>
                      {text.RADAR.PRINTA_RADAR}
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={10}>
                  <h2 className="pt-5">Mina svar</h2>
                  <Accordion activeKey={activeAccordionKey} ref={accordionRef}>
                    {survey.pages.map((page, i) => (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header
                          onClick={() => setActiveAccordionKey(i)}
                        >
                          {page.title["sv"]} {getPagePercentage(i)}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="accordeon-page-info">
                            {getPageInfo(i)}
                          </p>
                          <h3 className="fw-bold">Mina svar</h3>
                          <ul>
                            {page.elements
                              .filter((e) => e.type !== "html")
                              .map((element) => (
                                <>
                                  <li>{element.title["sv"]}</li>
                                  <Answer
                                    question={element}
                                    answer={orgResponse.response[element.name]}
                                  />
                                </>
                              ))}
                          </ul>
                          <Link to={"./../current-survey?page=" + i}>
                            <Button variant="hollow">
                              Gå till svarssida i enkäten
                            </Button>
                          </Link>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Row>
              {/* <Row>
              <div className="col-4 offset-4">
                <select
                  className="form-select"
                  aria-label="Jäömför med alla bostadbolag"
                  onChange={(e) => companySizeChanged(e.target.value)}
                >
                  {sizeOptions.map((size) => (
                    <option key={size.value} value={size.value}>
                      {size.title}
                    </option>
                  ))}
                </select>
              </div>
            </Row> */}
              <Row className="justify-content-center pb-5 pt-5">
                <Col md={10}>
                  <div className="d-flex justify-content-between">
                    <Link to={"./../next-step"}>
                      <Button variant="action">
                        {text.RADAR.GÅ_TILL_NÄSTA_STEG}
                      </Button>
                    </Link>

                    <Button variant="hollow" onClick={handleAnswersPrint}>
                      {text.RADAR.PRINTA_SVAR}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>

            <div style={{ visibility: "hidden", height: 0 }}>
              <>
                <RadarPrint
                  indicatorsData={indicatorsData}
                  survey={survey}
                  orgResponse={orgResponse}
                  ref={radarRef}
                  text={text}
                />
                <AnswersPrint
                  indicatorsData={indicatorsData}
                  survey={survey}
                  orgResponse={orgResponse}
                  ref={answersRef}
                  text={text}
                  getPageInfo={getPageInfo}
                />
              </>
            </div>
          </>
        )}
    </div>
  );
};

export default RadarPage;
