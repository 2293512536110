import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import useText from "services/useText";
import HTMLText from "components/HTMLText";
import "./style.scss";

export default () => {
  const text = useText();

  return (
    <div className="survey-completed">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h1>{text.ENKÄT_AVSLUT.TITLE}</h1>

            <HTMLText>{text.ENKÄT_AVSLUT.INTRO}</HTMLText>

            <Link to={"./../../radar"}>
              <Button variant="action">
                {text.ENKÄT_AVSLUT.GÅ_TILL_RESULTATET}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
