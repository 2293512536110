import { NavLink } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import ConditionalNavLink from "./conditionalNavLink";
import Tippy from "@tippyjs/react";

// const DigitalizationGroup = (role, orgId) => {
//   const roleConditional = role === "ORG_ADMIN" || role === "SYS_ADMIN";
//   const to = "/organizations/" + orgId + "/digitizing-group";
//   const text = "Digitaliseringsteam";

//   return (
//     <ConditionalNavLink
//       roleConditional={roleConditional}
//       to={to}
//       text={text}
//       activeRoute={"aaaaa"}
//     />
//   );
// };

const CurrentSituation = (role, orgId) => {
  const roleConditional = role === "EDITOR" || role === "ORG_ADMIN";
  const to = "/organizations/" + orgId + "/current-survey/introduction";
  const text = "Kartläggning";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={["current-survey"]}
    />
  );
};

const Radar = (role, orgId) => {
  const roleConditional = role !== "SYS_ADMIN" ? role !== "NOTLOGGEDIN" : false;
  const to = "/organizations/" + orgId + "/radar";
  const text = "Resultat";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={["radar"]}
    />
  );
};

const Comparison = (role, orgId) => {
  const roleConditional = role === "SYS_ADMIN";
  // const to = "/organizations/" + orgId + "/compare/it-prerequisites";
  const to = "/organizations/" + orgId + "/compare";
  const text = "Jämför bolag";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={["compare"]}
    />
  );
};

const NextStep = (role, orgId) => {
  const roleConditional = role !== "SYS_ADMIN" ? role !== "NOTLOGGEDIN" : false;
  const to = "/organizations/" + orgId + "/next-step";
  const text = "Nästa steg";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={["next-step"]}
    />
  );
};

const AboutStep = (role) => {
  const roleConditional = role !== "SYS_ADMIN" ? role !== "NOTLOGGEDIN" : false;
  const to = "/about";
  const text = "Om digitaliseringsradarn";

  return (
    <ConditionalNavLink
      roleConditional={true}
      to={to}
      text={text}
      activeRoute={["about"]}
    />
  );
};

const DigiRadar = (role) => {
  const roleConditional = role !== "SYS_ADMIN" ? role !== "NOTLOGGEDIN" : false;
  const to = "/digiradar-start";
  const text = "Digitaliseringsradarn";

  return (
    <ConditionalNavLink
      roleConditional={true}
      to={to}
      text={text}
      activeRoute={["digiradar-start"]}
    />
  );
};

const HowItWorks = (role) => {
  const roleConditional = role !== "SYS_ADMIN" ? role !== "NOTLOGGEDIN" : false;
  const to = "/introsteps";
  const text = "Så funkar det";

  return (
    <ConditionalNavLink
      roleConditional={true}
      to={to}
      text={text}
      activeRoute={["introsteps", "/step/1", "/step/2", "/step/3", "/step/4"]}
    />
  );
};

const OrgAdmin = (role) => {
  const roleConditional = role === "SYS_ADMIN";
  const to = "/organization";
  const text = "Organisationer";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={["organization"]}
    />
  );
};

const LoginLogout = (user, loginUrl, logoutUrl) => {
  return (
    <Nav.Item className="d-flex align-items-center">
      {user.username ? (
        <Tippy content={user.username}>
          <Button
            className="ms-1 signInOut"
            variant="light"
            href={logoutUrl}
            aria-label="logout"
          >
            Logga ut
          </Button>
        </Tippy>
      ) : (
        <NavLink to="/signin">
          <Button className="ms-1 signInOut" variant="light" aria-label="login">
            Logga in
          </Button>
        </NavLink>
      )}
    </Nav.Item>
  );
};

const Register = (user) => {
  return (
    <Nav.Item className="d-flex align-items-center">
      {user.username ? (
        <></>
      ) : (
        <NavLink to="/register">
          <Button
            className="ms-2 signInOut"
            variant="light"
            aria-label="logout"
          >
            Registera
          </Button>
        </NavLink>
      )}
    </Nav.Item>
  );
};

export default {
  // DigitalizationGroup,
  CurrentSituation,
  Radar,
  Comparison,
  NextStep,
  AboutStep,
  LoginLogout,
  OrgAdmin,
  Register,
  HowItWorks,
  DigiRadar,
};
