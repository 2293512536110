import { useState } from "react";
import * as Survey from "survey-react";
import useSurvey from "./useSurvey";

function useSurveyModel() {
  const survey = useSurvey();
  const [model, setModel] = useState(new Survey.Model(survey));

  return model;
}

export default useSurveyModel;
