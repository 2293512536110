import { faFileAudio } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const { useState, useEffect } = require("react");

const NotifyUpdate = () => {
  const [show, setShow] = useState(false);
  let url = "/index.html?t=";
  let previousHash = null;
  const interval = 10000;
  let timer = null;

  useEffect(async () => {
    await fetchSource();
    timer = setInterval(fetchSource, interval);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const hash = (str) => {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const fetchSource = async () => {
    const cacheBuster = new Date().getTime();
    const response = await fetch(url + cacheBuster);
    const html = await response.text();
    const htmlHash = hash(html);

    if (!previousHash) {
      previousHash = htmlHash;
      return;
    }
    if (previousHash !== htmlHash) {
      previousHash = htmlHash;
      clearInterval(timer);
      setShow(true);
    }
  };

  return (
    <>
      {show && (
        <div className="container-fluid notify-update d-flex justify-content-center align-items-center">
          <div className="mx-2">
            Det finns en ny version av Digitaliseringsradarn.{" "}
          </div>
          <button
            onClick={() => window.location.reload()}
            className="btn btn-secondary"
          >
            Uppdatera nu!
          </button>
        </div>
      )}
    </>
  );
};

export default NotifyUpdate;
