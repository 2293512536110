import http from "utils/http";
import { useEffect, useState } from "react";

let allTexts = null;

function useText() {
  const [text, setText] = useState(allTexts);

  useEffect(() => {
    async function getText() {
      if (allTexts) {
        setText(allTexts);
      } else {
        const response = await http.get("/api/texts");
        allTexts = response.data;
        setText(response.data);
      }
    }
    getText();
  }, []);

  return text;
}

export default useText;
