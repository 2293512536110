import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faCircleNotch,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const OrganizationContent = (props) => {
  const { org, addOrgEditor, removeOrgEditor } = props;

  function orgEditorList() {
    return [
      org.editors.map((editor, index) => (
        <div className="orgUserRow py-1 m-0" key={index}>
          <p className="m-0 ms-2">{editor.email}</p>
          <Button
            className="btn-danger btn-sm me-2"
            onClick={() => {
              removeOrgEditor(editor);
            }}
          >
            Ta bort
            <FontAwesomeIcon
              icon={faTrash}
              className="ms-1"
              aria-hidden="true"
            />
          </Button>
        </div>
      )),
    ];
  }

  return (
    <div>
      <div className="d-flex align-items-baseline py-1 m-0">
        <h4 className="pe-2 mt-4">Medarbetare</h4>
      </div>
      {!org.editors && (
        <>
          <FontAwesomeIcon icon={faCircleNotch} size="4x" className="fa-spin">
            <span className="visually-hidden">Loading...</span>
          </FontAwesomeIcon>
        </>
      )}
      {org.editors && orgEditorList()}
      <Button
        variant="action"
        className="mt-4"
        onClick={() => addOrgEditor(org)}
      >
        <FontAwesomeIcon icon={faPlus} className="me-1" aria-hidden="true" />
        Lägg till en medarbetare
      </Button>
    </div>
  );
};

export default OrganizationContent;
