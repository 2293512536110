import http from "utils/http";
import { useState, useEffect } from "react";

function useCreateResponseVersion(organizationId) {
  const createVersion = async (surveyResponse, completed) => {
    const response = await http.post(
      "/api/organizations/" + organizationId + "/surveys"
    );
  };

  return { createVersion };
}

export default useCreateResponseVersion;
