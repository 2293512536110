import "./style.scss";
import useText from "services/useText";

export default ({ activeStep, onClick }) => {
  const text = useText();
  return (
    <ul id="progress">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((step, i) => (
        <li
          className={activeStep > i ? "done" : activeStep === i ? "active" : ""}
          key={i}
        >
          <span onClick={() => onClick(i)}>{text.ENKÄT["STEG_" + step]}</span>
        </li>
      ))}
    </ul>
  );
};
