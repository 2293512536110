import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import "./styles.scss";

const IntroSteps = () => {
  return (
    <div className="intro-steps route-page">
      <Container>
        <Row>
          <Col md={8} className="mb-5">
            <h1>Hur fungerar digitaliseringsradarn?</h1>
            <p>
              En nulägesanalys är ett sätt att på ett strukturerat sätt
              kartlägga er organisations status och förutsättningar här och nu.
              För att sedan kunna göra relevanta prioriteringar i en
              förbättringsresa. I detta fall specifikt med fokus på
              digitalisering.
            </p>
            <p>
              Detta verktyg är designat för att hjälpa just
              byggherreorganisationer och har fokus på byggherrens unika roll,
              krav, förväntningar och position i värdekedjan. Med frågor som
              kartlägger organisationens förutsättningar och interna arbetssätt,
              gränssnitt till andra aktörer och slutprodukten.
            </p>
            <p>
              Samla ett antal deltagare från er organisation, besvara frågorna
              och skapa en gemensam förståelse för hur det ser ut hos just er.
              Innan ni tar vidare steg mot ökad digitalisering.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mb-5">
            <div>
              <img src="steps.svg" />
              <div className="d-flex justify-content-between">
                <div className="text-step">
                  1. Kolla genom frågorna och samla olika deltagare, roller och
                  kompetenser för att besvara frågorna på ett relevant sätt.
                  Detta ger er organisation en god överblick över nuläget.
                </div>
                <div className="text-step">
                  2. Skapa er en gemensam bild innan ni gör påbörjar arbetet.
                  Varför ni ska genomföra en nulägesanalys? Vad det innebär för
                  er? Vad vill vi uppnå med digitalisering? Hur ska resultatet
                  tas vidare?
                </div>
                <div className="text-step">
                  3. Genomför nulägesanalysen genom att besvara ett antal frågor
                  som kartlägger er organisations nuläge.
                </div>
                <div className="text-step">
                  4. Tolka ert resultat och vad resultatet innebär för just er
                  organisation och hur det kan bidra till prioriteringar för det
                  fortsatta utvecklingsarbetet.
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Link to="/step/1">
              <Button variant="action" className="mb-4">
                Gå vidare
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IntroSteps;
