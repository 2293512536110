import http from "utils/http";
import { useEffect, useState } from "react";

function useUser() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getUser() {
      const response = await http.get("/api/me");
      setUser(response.data);
    }
    getUser();
  }, []);

  return user;
}

export default useUser;
