import { Button, Col, Container, Row, Form } from "react-bootstrap";
import useText from "services/useText";
import { useState, useRef } from "react";
import HTMLText from "components/HTMLText";
import http from "utils/http";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const Login = () => {
  // const loginUrl =
  //   process.env.NODE_ENV === "development"
  //     ? "http://localhost:3001/api/login"
  //     : "/api/login";
  const text = useText();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const usernameRef = useRef();
  const passwordRef = useRef();

  const submitLogin = async (evt) => {
    evt.preventDefault();
    try {
      const result = await http.post("/api/auth/local", { username, password });
      const user = result.data.user;
      if (user.role === "SYS_ADMIN") {
        window.location.href = "/organization";
      } else {
        window.location.href = "/";
      }
    } catch (err) {
      setMessage("Misslyckades att logga in.");
    }
  };

  return (
    <Container fluid className="content-container login-page">
      <img src="/squares1.png" className="squares-left" />
      <Container>
        <Row>
          <Col className="form-column">
            <h1 className="text-white">Logga in</h1>

            <Form className="mb-4" onSubmit={submitLogin}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Användarnamn</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={username}
                  onChange={(evt) => setUsername(evt.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Lösenord</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  value={password}
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </Form.Group>

              <Button variant="action" type="submit" className="mt-4">
                Logga in
              </Button>
              {message.length > 0 && (
                <p className="text-white fs-6 mt-4 mb-0">{message}</p>
              )}

              <p className="text-white mt-4 fs-6">
                Har du problem med att logga in, kontakta{" "}
                <a className="text-white" href="mailto:saskia@foginnovation.se">
                  saskia@foginnovation.se
                </a>
                via mail.
              </p>
            </Form>
          </Col>
          <Col className="position-relative">
            <img className="position-absolute" src="squares2.png" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Login;
